import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneralService } from 'src/app/api/general.service';
import { PickersService } from 'src/app/api/pickers.service';
import { TitleService } from 'src/app/services/title.service';

export interface PickerData {
  id: number;
  name: string;
  comment: string;
  cardid: string;
  productid: number;
}

export interface NameMapping {
  id : number;
  name : string;
}
export interface APINameIDMap {
  pickers: NameMapping[],
  scales: NameMapping[],
  products: NameMapping[]
}

@Component({
  selector: 'app-picker-edit',
  templateUrl: './picker-edit.component.html',
  styleUrls: ['./picker-edit.component.css']
})
export class PickerEditComponent implements OnInit {

  id: number;
  name: string;
  comment: string;
  cardid: string;
  productid: number;

  private sub : any;
  Products : NameMapping[];


  constructor(private route: ActivatedRoute, private router: Router, private pickers : PickersService, private titleService: TitleService, private generalAPI : GeneralService, private spinner: NgxSpinnerService) { 
    
    this.titleService.setTitle($localize`Erntehelfer bearbeiten`);

    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id']; // (+) converts string 'id' to a number

      this.spinner.show();
      this.pickers.getPickerByID(this.id).then((data) => this.onData(data));
   });

   generalAPI.getAllIDNameMappings().then((data) => this.onIDMapping(data));
  }

  onIDMapping(data) : void {
    let rawData = <APINameIDMap>data;
    this.Products = rawData.products;
  }

  getProductName(id) : string {
    if(id == null && id <= 0)
      return "-/-";

    for(let data of this.Products)
    {
      if(data.id == id)
        return data.name;
    }

    return "unkown (" + id + ")";
  }

  onData(data) {
      let dataParsed = <PickerData>data;

      this.name = dataParsed.name;
      this.comment = dataParsed.comment;
      this.cardid = dataParsed.cardid;      

      if(dataParsed.productid != null && dataParsed.productid > 0)
      {
        this.productid = dataParsed.productid;
      }
      else
      {
        this.productid = -1;
      }

      this.spinner.hide();
  }

  ngOnInit(): void {
    
  }

  ngAfterContentInit(): void {
    
  }

  save(): void {
    this.pickers.savePicker(this.id, this.name, this.comment, this.cardid, this.productid).then((data) => this.onDataSaved(data));
  }

  async onDataSaved(data){
    await this.router.navigate(['./dashboard/picker']);
  }

  async exit() {
    await this.router.navigate(['./dashboard/picker']);  
  }
}
