import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductsService } from 'src/app/api/products.service';
import { TitleService } from 'src/app/services/title.service';

export interface ResultAdd {
  id : number;
  success : boolean;
}
@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.css']
})
export class ProductAddComponent implements OnInit {

  name: string;

  constructor(private route: ActivatedRoute, private router: Router, private products : ProductsService, private titleService: TitleService, private spinner: NgxSpinnerService) {
    this.titleService.setTitle($localize`Produkt hinzufügen`);
   }

  ngOnInit(): void {
  }

  save(): void {
    this.products.addProduct(this.name).then((data) => this.onDataSaved(data));
  }

  async onDataSaved(data){
    let result = <ResultAdd>data;

    await this.router.navigate(['./dashboard/productedit', result.id ]);
  }

  async exit() {
    await this.router.navigate(['./dashboard/products']);  
  }

}
