import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { GeneralService } from 'src/app/api/general.service';
import { HarvestEventsService } from 'src/app/api/harvest-events.service';
import { TitleService } from 'src/app/services/title.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver';

export interface UserData {
  uuid: string;
  picker: string;
  weight: string;
  product: string;
  datetime: string;
  scale: string;
  tara: number;
  alibiid: number;
  price: number;
}
export interface NameMapping {
  id : number;
  name : string;
}

export interface APINameIDMap {
  pickers: NameMapping[],
  scales: NameMapping[],
  products: NameMapping[]
}


@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.css']
})
export class TablesComponent implements OnInit {

  displayedColumns: string[] = ['datetime', 'picker', 'weight', 'tara', 'pay', 'alibiid', 'product', 'scale'];
  dataSource: MatTableDataSource<UserData>;
  rawData : UserData[]

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dateRangePicker: FormGroup;
  
  selectedPicker = new FormControl();
  @ViewChild('allSelectedPickers') private allSelectedPickers: MatOption;

  selectedScales = new FormControl();
  @ViewChild('allSelectedScales') private allSelectedScales: MatOption;

  selectedProducts = new FormControl();
  @ViewChild('allSelectedProducts') private allSelectedProducts: MatOption;

  Pickers : NameMapping[];
  Products : NameMapping[];
  Scales : NameMapping[];

  constructor(private harvest : HarvestEventsService, private generalAPI : GeneralService, private titleService: TitleService, private spinner: NgxSpinnerService) { 
    
    this.titleService.setTitle("Übersicht Ernteevents");

    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    this.dateRangePicker = new FormGroup({
      start: new FormControl(new Date(year, month, 1)),
      end: new FormControl(new Date(year, month, new Date(year, month + 1, 0).getDate()))
    });

    this.spinner.show();
    generalAPI.getAllIDNameMappings().then((data) => this.onDataMapping(data));
    harvest.getAllHarvestEvents().then((data) => this.onData(data));
  }

  ngOnInit() : void {
    
  }

  updateView() : void{
    // Array klonen
    let selectedScalesValues : String[] = Object.assign([],this.selectedScales.value);
    let selectedProductsValues : String[] = Object.assign([],this.selectedProducts.value);
    let selectedPickersValues : String[] = Object.assign([],this.selectedPicker.value);

    // Prüfen, wenn nichts ausgewählt wurde
    if(!(selectedScalesValues?.length > 0) || !(selectedProductsValues?.length > 0) || !(selectedPickersValues?.length > 0))
    {
      // something is nothing selected => empty table
      this.rawData = <UserData[]>[];
      this.dataSource = new MatTableDataSource(this.rawData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      
      return;
    }

    // alle Markierung entfernen
    if(selectedScalesValues[0] == "-1")
    {
      selectedScalesValues.splice(0, 1);
    }
    if(selectedProductsValues[0] == "-1")
    {
      selectedProductsValues.splice(0, 1);
    }
    if(selectedPickersValues[0] == "-1")
    {
      selectedPickersValues.splice(0, 1);
    }
    
    // Start- und End-Datum auslesen
    let startDate = this.dateRangePicker.value.start.toISOString().slice(0, 19).replace('T', ' ');
    let endDate = this.dateRangePicker.value.end.toISOString().slice(0, 19).replace('T', ' ');

    let timeSpan = this.dateRangePicker.value.end - this.dateRangePicker.value.start;

    // timeSpan in Tage umrechnen
    timeSpan = timeSpan / 1000 / 3600 / 24;

    if(timeSpan > 100)
    {
      this.rawData = <UserData[]>[];
      this.dataSource = new MatTableDataSource(this.rawData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      
      return;
    }

    this.spinner.show();
    this.harvest.getFilteredHarvestEvents(selectedPickersValues, selectedProductsValues, selectedScalesValues, startDate, endDate).then((data) => this.onData(data));
  }

  downloadCSV() : void{
    // Array klonen
    let selectedScalesValues : String[] = Object.assign([],this.selectedScales.value);
    let selectedProductsValues : String[] = Object.assign([],this.selectedProducts.value);
    let selectedPickersValues : String[] = Object.assign([],this.selectedPicker.value);

    // Prüfen, wenn nichts ausgewählt wurde
    if(!(selectedScalesValues?.length > 0) || !(selectedProductsValues?.length > 0) || !(selectedPickersValues?.length > 0))
    {
      // something is nothing selected => empty table
      return;
    }

    // alle Markierung entfernen
    if(selectedScalesValues[0] == "-1")
    {
      selectedScalesValues.splice(0, 1);
    }
    if(selectedProductsValues[0] == "-1")
    {
      selectedProductsValues.splice(0, 1);
    }
    if(selectedPickersValues[0] == "-1")
    {
      selectedPickersValues.splice(0, 1);
    }
    
    // Start- und End-Datum auslesen
    let startDate = this.dateRangePicker.value.start.toISOString().slice(0, 19).replace('T', ' ');
    let endDate = this.dateRangePicker.value.end.toISOString().slice(0, 19).replace('T', ' ');

    let timeSpan = this.dateRangePicker.value.end - this.dateRangePicker.value.start;

    // timeSpan in Tage umrechnen
    timeSpan = timeSpan / 1000 / 3600 / 24;

    if(timeSpan > 1000)
    {
      return;
    }

    const options = {  year: 'numeric', month: 'numeric', day: 'numeric' };
    const filename = "sugi (" + this.dateRangePicker.value.start.toLocaleDateString('de-DE', options) + " - " + this.dateRangePicker.value.end.toLocaleDateString('de-DE', options) + ")";

    this.spinner.show();
    this.harvest.getFilteredHarvestEventsCSV(selectedPickersValues, selectedProductsValues, selectedScalesValues, startDate, endDate).subscribe((resp: any) => {
      const blob = new Blob([resp], { type: 'application/octet-stream' });
      this.spinner.hide();
      saveAs(blob, filename + ".csv");
   });    
  }


  downloadXLSX() : void{
    // Array klonen
    let selectedScalesValues : String[] = Object.assign([],this.selectedScales.value);
    let selectedProductsValues : String[] = Object.assign([],this.selectedProducts.value);
    let selectedPickersValues : String[] = Object.assign([],this.selectedPicker.value);

    // Prüfen, wenn nichts ausgewählt wurde
    if(!(selectedScalesValues?.length > 0) || !(selectedProductsValues?.length > 0) || !(selectedPickersValues?.length > 0))
    {
      // something is nothing selected => empty table
      return;
    }

    // alle Markierung entfernen
    if(selectedScalesValues[0] == "-1")
    {
      selectedScalesValues.splice(0, 1);
    }
    if(selectedProductsValues[0] == "-1")
    {
      selectedProductsValues.splice(0, 1);
    }
    if(selectedPickersValues[0] == "-1")
    {
      selectedPickersValues.splice(0, 1);
    }
    
    // Start- und End-Datum auslesen
    let startDate = this.dateRangePicker.value.start.toISOString().slice(0, 19).replace('T', ' ');
    let endDate = this.dateRangePicker.value.end.toISOString().slice(0, 19).replace('T', ' ');

    let timeSpan = this.dateRangePicker.value.end - this.dateRangePicker.value.start;

    // timeSpan in Tage umrechnen
    timeSpan = timeSpan / 1000 / 3600 / 24;

    if(timeSpan > 1000)
    {
      return;
    }

    const options = {  year: 'numeric', month: 'numeric', day: 'numeric' };
    const filename = "sugi (" + this.dateRangePicker.value.start.toLocaleDateString('de-DE', options) + " - " + this.dateRangePicker.value.end.toLocaleDateString('de-DE', options) + ")";

    this.spinner.show();
    this.harvest.getFilteredHarvestEventsXLSX(selectedPickersValues, selectedProductsValues, selectedScalesValues, startDate, endDate).subscribe((resp: any) => {
      const blob = new Blob([resp], { type: 'application/octet-stream' });
      this.spinner.hide();
      saveAs(blob, filename + ".xlsx");
   });    
  }

  selectionScalesChanged(id : number) : void {
    
    let values : String[] = this.selectedScales.value;
    
    let isChecked : boolean = false;

    for(let val of values)
    {
      if(val == "-1")
        continue;

      if(val == id.toString())
      {
        isChecked = true;
        break;
      }
    }

    if(!isChecked)
    {
      // deselect all
      this.allSelectedScales.deselect();
    }    
    else if(values.length == this.Scales.length)
    {
      this.allSelectedScales.select();
    }

  }

  selectionProductsChanged(id : number) : void {
    
    let values : String[] = this.selectedProducts.value;
    
    let isChecked : boolean = false;

    for(let val of values)
    {
      if(val == "-1")
        continue;

      if(val == id.toString())
      {
        isChecked = true;
        break;
      }
    }

    if(!isChecked)
    {
      // deselect all
      this.allSelectedProducts.deselect();
    }
    else if(values.length == this.Products.length)
    {
      this.allSelectedProducts.select();
    }
  }

  selectionPickersChanged(id : number) : void {
    
    let values : String[] = this.selectedPicker.value;
    
    let isChecked : boolean = false;

    for(let val of values)
    {
      if(val == "-1")
        continue;

      if(val == id.toString())
      {
        isChecked = true;
        break;
      }
    }

    if(!isChecked)
    {
      // deselect all
      this.allSelectedPickers.deselect();
    }
    else if(values.length == this.Pickers.length)
    {
      this.allSelectedPickers.select();
    }
  }


  getScaleName(id) : string {
    for(let data of this.Scales)
    {
      if(data.id == id)
        return data.name;
    }

    return "unkown (" + id + ")";
  }

  getProductName(id) : string {
    for(let data of this.Products)
    {
      if(data.id == id)
        return data.name;
    }

    return "unkown (" + id + ")";
  }

  getPickerName(id) : string {
    for(let data of this.Pickers)
    {
      if(data.id == id)
        return data.name;
    }

    return "unkown (" + id + ")";
  }

  getFormatWeight(weight) : string{

    if(weight == null || weight == undefined)
    {
      return "0 kg";
    }

    let weightG : number = weight;
    let weightKG = weightG / 1000;

    return weightKG.toFixed(2) + " kg";
  }

  getFormatMoney(money) : string{

    if(money == null || money == undefined)
    {
      return "0,00 €";
    }

    let moneyCent : number = money;
    let moneyEuro = moneyCent / 100;

    return moneyEuro.toFixed(2) + " €";
  }  

  onData(data) : void {
    this.spinner.hide();

    this.rawData = <UserData[]>data;
    this.dataSource = new MatTableDataSource(this.rawData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onDataMapping(data) : void {
    let rawData = <APINameIDMap>data;
    this.Pickers = rawData.pickers;
    this.Products = rawData.products;
    this.Scales = rawData.scales;

    this.selectedPicker.patchValue([-1, ...this.Pickers.map(item => item.id)]);
    this.selectedScales.patchValue([-1, ...this.Scales.map(item => item.id)]);
    this.selectedProducts.patchValue([-1, ...this.Products.map(item => item.id)]);
  }

  toggleAllSelectedPickers() : void{
    if (this.allSelectedPickers.selected) {
      this.selectedPicker.patchValue([-1, ...this.Pickers.map(item => item.id)]);
    } else {
      this.selectedPicker.patchValue([]);
    }
  }

  toggleAllSelectedScales() : void{
    if (this.allSelectedScales.selected) {
      this.selectedScales.patchValue([-1, ...this.Scales.map(item => item.id)]);
    } else {
      this.selectedScales.patchValue([]);
    }
  }

  toggleAllSelectedProducts() : void{
    if (this.allSelectedProducts.selected) {
      this.selectedProducts.patchValue([-1, ...this.Products.map(item => item.id)]);
    } else {
      this.selectedProducts.patchValue([]);
    }
  }

}