import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from 'src/app/services/title.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TimeEventsService } from 'src/app/api/time-events.service';

export interface TimeRawData {
  uuid: string;
  name: string;
  time: string;
  event: number;
}
@Component({
  selector: 'app-times-allRaw',
  templateUrl: './times-allRaw.component.html',
  styleUrls: ['./times-allRaw.component.css'],
  providers:[]
})
export class TimesAllRawComponent implements OnInit {

  displayedColumns: string[] = ['uuid', 'name', 'action', 'time'];
  dataSource: MatTableDataSource<TimeRawData>;
  rawData : TimeRawData[]

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private time : TimeEventsService, private route: ActivatedRoute, private router: Router, private titleService: TitleService, private spinner: NgxSpinnerService) {
    this.titleService.setTitle($localize`Übersicht Zeiterfassung`);

    if(this.rawData == null)
    {
      this.spinner.show();
      time.getAllRawTimeEvents().then((data) => this.onData(data));
    }
    else
    {
      this.dataSource = new MatTableDataSource(this.rawData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
   }

  onData(data) : void {
    this.spinner.hide();
    this.rawData = <TimeRawData[]>data;
    this.dataSource = new MatTableDataSource(this.rawData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getActionName(action) : string {
    switch(action)
    {
      case "1":
        return "Kommen";

      case "2":
        return "Gehen";

      case "3":
        return "Kommen (Pause)";

      case "4":
        return "Gehen (Pause)";
    }

    return "undefined (" + action + ")";
  }


  ngOnInit(): void {
    
  }
}
