import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginFormComponent } from './login-form/login-form.component';
import { PwresetFormComponent } from './pwreset-form/pwreset-form.component';

import '@angular/localize/init'

const routes: Routes = [
  {
    path: '',
    component: LoginFormComponent
  },
  {
    path: 'reset',
    component: PwresetFormComponent
  },
  {
    path: 'dashboard',
    loadChildren: () => import(`./dashboard/dashboard.module`).then(m => m.DashboardModule)
  }
];  

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
