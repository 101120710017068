import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pwreset-form',
  templateUrl: './pwreset-form.component.html',
  styleUrls: ['./pwreset-form.component.css']
})
export class PwresetFormComponent implements OnInit {

  form: FormGroup;
  loginSend: boolean = false;
  
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router) { 
    document.body.style.background = '#19002c';
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]      
    });
  }

  async onSubmit() 
  {
    if(this.form.valid && this.loginSend == false)
    {
      this.loginSend = true;
      this.form.disable();
    }
  }

  async back() 
  {
    await this.router.navigate(['/']);
  }

}
