import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginFormComponent } from './login-form/login-form.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { PickerComponent } from './views/picker/picker.component';
import { TablesComponent } from './views/tables/tables.component';
import { ScalesComponent } from './views/scales/scales.component';
import { StatisticComponent } from './views/statistic/statistic.component';
import { ExportComponent } from './views/export/export.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxKjuaModule } from 'ngx-kjua';
import { ScalesViewComponent } from './views/scales-view/scales-view.component';
import { HttpClientModule } from '@angular/common/http';
import { ProductsComponent } from './views/products/products.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { PickerAddComponent } from './views/picker-add/picker-add.component';
import { PickerEditComponent } from './views/picker-edit/picker-edit.component';
import { ProductEditComponent } from './views/product-edit/product-edit.component';
import { ProductAddComponent } from './views/product-add/product-add.component';
import { ScalesEditComponent } from './views/scales-edit/scales-edit.component';
import { PwresetFormComponent } from './pwreset-form/pwreset-form.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import {MatTabsModule} from '@angular/material/tabs';
import { TimesAllRawComponent } from './views/times-allRaw/times-allRaw.component';
import { TimesAllComponent } from './views/times-all/times-all.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginFormComponent,
    TimesAllRawComponent,
    TimesAllComponent,
    PickerComponent,
    TablesComponent,
    ScalesComponent,
    StatisticComponent,
    ExportComponent,
    ScalesViewComponent,
    ProductsComponent,
    PickerAddComponent,
    PickerEditComponent,
    ProductEditComponent,
    ProductAddComponent,
    ScalesEditComponent,
    PwresetFormComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    MatMenuModule,
    FlexLayoutModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    NgxChartsModule,
    NgxKjuaModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    NgxSpinnerModule,
    MatTabsModule
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'}   
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
