import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductsService } from 'src/app/api/products.service';
import { TitleService } from 'src/app/services/title.service';

export interface ProductData {
  id: number;
  name: string;
  comment: string;
  tara: number;
  pay: number;
}

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.css']
})
export class ProductEditComponent implements OnInit {

  id: number;
  name: string;
  comment: string;
  tara: number;
  price: number;

  sub: any;

  constructor(private route: ActivatedRoute, private router: Router,  private titleService: TitleService, private products : ProductsService, private spinner: NgxSpinnerService) { 
    this.titleService.setTitle($localize`Produkt bearbeiten`);

    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id']; // (+) converts string 'id' to a number

      this.spinner.show();
      this.products.getProductByID(this.id).then((data) => this.onData(data));
   });
  }

  onData(data) {
    let dataParsed = <ProductData>data;

    this.name = dataParsed.name;
    this.comment = dataParsed.comment;
    this.tara = dataParsed.tara / 1000;      
    this.price = dataParsed.pay / 100;      

    this.spinner.hide();
  }

  ngOnInit(): void {
  }

  save(): void {
    this.products.saveProduct(this.id, this.name, this.comment, this.tara * 1000, this.price * 100).then((data) => this.onDataSaved(data));
  }

  async onDataSaved(data){
    await this.router.navigate(['./dashboard/products']);
  }

  async exit() {
    await this.router.navigate(['./dashboard/products']);  
  }
}
