<div class="content">
  <div class="mat-elevation-z8" style="padding: 16px; margin-bottom: 16px;">
    <p i18n>Konfiguriere den Filter, um ein Ernte-Ereignis zu finden</p>
    <mat-form-field appearance="fill">
      <mat-label i18n>Zeitraum</mat-label>
      <mat-date-range-input
        [formGroup]="dateRangePicker"
        [rangePicker]="dateRangePickerPick"
        [comparisonStart]="dateRangePicker.value.start"
        [comparisonEnd]="dateRangePicker.value.end">
        <input matStartDate i18n-placeholder placeholder="Start" formControlName="start">
        <input matEndDate i18n-placeholder placeholder="Ende" formControlName="end">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="dateRangePickerPick"></mat-datepicker-toggle>
      <mat-date-range-picker #dateRangePickerPick></mat-date-range-picker>

      <mat-error *ngIf="dateRangePicker.controls.start.hasError('matStartDateInvalid')" i18n>Ungültiges Start-Datum</mat-error>
      <mat-error *ngIf="dateRangePicker.controls.end.hasError('matEndDateInvalid')" i18n>Ungültiges End-Datum</mat-error>
      <mat-error *ngIf="((dateRangePicker.value.end - dateRangePicker.value.start) / 1000 / 3600 / 24)  > 100" i18n>zu große Zeitspanne!</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 300px">
      <mat-label><span i18n>Erntehelfer</span>:</mat-label>
      <mat-select [formControl]="selectedPicker" multiple>
        <mat-select-trigger>
          <div *ngIf="selectedPicker.value?.length > 0 && selectedPicker.value[0] != -1; then thenBlock1 else elseBlock1"></div>
          <ng-template #thenBlock1>
            {{selectedPicker.value ? this.getPickerName(selectedPicker.value[0]) : ''}}          
            <span *ngIf="selectedPicker.value?.length > 1" class="additional-selection">
              (+{{selectedPicker.value.length - 1}} {{selectedPicker.value?.length === 2 ? 'weiterer' : 'weitere'}})
            </span>        
          </ng-template>
          <ng-template #elseBlock1>            
            <div *ngIf="selectedPicker.value?.length > 0 && selectedPicker.value[0] == -1; then thenBlock11 else elseBlock11"></div>
            <ng-template #thenBlock11>
              <span i18n>Alle Erntehelfer ausgewählt</span>
            </ng-template>
            <ng-template #elseBlock11>
              <span i18n>keine Erntehelfer ausgewählt</span>
            </ng-template>  
          </ng-template>                    
        </mat-select-trigger>
        <mat-option #allSelectedPickers (click)="toggleAllSelectedPickers()" [value]="-1">Alle</mat-option>
        <mat-option *ngFor="let picker of Pickers"  (click)="selectionPickersChanged(picker.id)"  [value]="picker.id">{{picker.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 300px">
      <mat-label><span i18n>Waagen</span>:</mat-label>
      <mat-select [formControl]="selectedScales" multiple>
        <mat-select-trigger>
          <div *ngIf="selectedScales.value && selectedScales.value.length > 0 && selectedScales.value[0] != -1; then thenBlock2 else elseBlock2"></div>
          <ng-template #thenBlock2>
            {{selectedScales.value ? this.getScaleName(selectedScales.value[0]) : ''}}          
            <span *ngIf="selectedScales.value?.length > 1" class="additional-selection">
              (+{{selectedScales.value.length - 1}} {{selectedScales.value?.length === 2 ? 'weitere' : 'weitere'}})
            </span>        
          </ng-template>
          <ng-template #elseBlock2>            
            <div *ngIf="selectedScales.value && selectedScales.value.length > 0 && selectedScales.value[0] == -1; then thenBlock22 else elseBlock22"></div>
            <ng-template #thenBlock22>
              <span i18n>Alle Waagen ausgewählt</span>
            </ng-template>
            <ng-template #elseBlock22>
              <span i18n>keine Waage ausgewählt</span>
            </ng-template>  
          </ng-template>                    
        </mat-select-trigger>
        <mat-option #allSelectedScales (click)="toggleAllSelectedScales()" [value]="-1">Alle</mat-option>
        <mat-option *ngFor="let scale of Scales" (click)="selectionScalesChanged(scale.id)" [value]="scale.id">{{scale.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 300px">
      <mat-label><span i18n>Produkte</span>:</mat-label>
      <mat-select [formControl]="selectedProducts" multiple>
        <mat-select-trigger>
          <div *ngIf="selectedProducts.value?.length > 0 && selectedProducts.value[0] != -1; then thenBlock3 else elseBlock3"></div>
          <ng-template #thenBlock3>
            {{selectedProducts.value ? this.getProductName(selectedProducts.value[0]) : ''}}          
            <span *ngIf="selectedProducts.value?.length > 1" class="additional-selection">
              (+{{selectedProducts.value.length - 1}} {{selectedProducts.value?.length === 2 ? 'weiteres' : 'weitere'}})
            </span>        
          </ng-template>
          <ng-template #elseBlock3>            
            <div *ngIf="selectedProducts.value?.length > 0 && selectedProducts.value[0] == -1; then thenBlock33"></div>
            <ng-template #thenBlock33>
              <span i18n>Alle Produkte ausgewählt</span>
            </ng-template>
            <div *ngIf="!selectedProducts.value || selectedProducts.value?.length == 0; then elseBlock33"></div>
            <ng-template #elseBlock33>
              <span i18n>keine Produkte ausgewählt</span>
            </ng-template>  
          </ng-template>                    
        </mat-select-trigger>
        <mat-option #allSelectedProducts (click)="toggleAllSelectedProducts()" [value]="-1">Alle</mat-option>
        <mat-option *ngFor="let product of Products"  (click)="selectionProductsChanged(product.id)" [value]="product.id">{{product.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    
    <button mat-raised-button (click)="updateView()" color="success" style="margin:5px" i18n>Anzeigen</button>

    <button mat-raised-button (click)="downloadCSV()" color="primary" style="margin:5px" i18n>CSV</button>

    <button mat-raised-button (click)="downloadXLSX()" color="primary" style="margin:5px" i18n>XLSX</button>

    <br>
    
    <mat-error *ngIf="dateRangePicker.controls.start.hasError('matStartDateInvalid')" i18n>Ungültiges Start-Datum</mat-error>
    <mat-error *ngIf="dateRangePicker.controls.end.hasError('matEndDateInvalid')" i18n>Ungültiges End-Datum</mat-error>
    <mat-error *ngIf="((dateRangePicker.value.end - dateRangePicker.value.start) / 1000 / 3600 / 24)  > 100" i18n>zu große Zeitspanne!</mat-error>

  </div>

  <div class="mat-elevation-z8">
      <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
        <!-- <p class="loading">Loading...</p> -->
      </ngx-spinner>

      <table mat-table [dataSource]="dataSource" matSort>
    
        <ng-container matColumnDef="uuid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>UUID</th>
          <td mat-cell *matCellDef="let row"> {{row.uuid}} </td>
        </ng-container>
    
        <ng-container matColumnDef="picker">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Erntehelfer</th>
          <td mat-cell *matCellDef="let row"> {{this.getPickerName(row.picker)}} </td>
        </ng-container>
    
        <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Gewicht</th>
          <td mat-cell *matCellDef="let row"> {{this.getFormatWeight(row.weight)}} </td>
        </ng-container>

        <ng-container matColumnDef="tara">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tara</th>
          <td mat-cell *matCellDef="let row"> {{this.getFormatWeight(row.tara)}} </td>
        </ng-container>

        <ng-container matColumnDef="pay">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Bezahlung</th>
          <td mat-cell *matCellDef="let row"> {{this.getFormatMoney(row.weight / 1000 * row.price)}} </td>
        </ng-container>

        <ng-container matColumnDef="alibiid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Alibi-ID</th>
          <td mat-cell *matCellDef="let row"> {{row.alibiid}} </td>
        </ng-container>
    
        <ng-container matColumnDef="product">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Produkt</th>
          <td mat-cell *matCellDef="let row"> {{this.getProductName(row.product)}} </td>
        </ng-container>

        <ng-container matColumnDef="datetime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Datum</th>
          <td mat-cell *matCellDef="let row"> {{row.datetime}} </td>
        </ng-container>

        <ng-container matColumnDef="scale">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Waage</th>
          <td mat-cell *matCellDef="let row"> {{this.getScaleName(row.scale)}} </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4" i18n>Es gibt keine Ernte-Events zum ausgewählten Filter</td>
        </tr>
      </table>
  
    <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]"></mat-paginator>
  </div>
</div>