<div class="content">
    <div fxLayout="row wrap">      
        <mat-card class="overview-card">
            <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
                <!-- <p class="loading">Loading...</p> -->
            </ngx-spinner>
            <mat-card-header>
                <mat-card-title i18n>Produkt hinzufügen</mat-card-title>
            </mat-card-header>
            <mat-card-content class="overview-card-content">                
                <mat-form-field class="example-full-width">
                <mat-label i18n>Name</mat-label>
                    <input matInput #nameInput maxlength="100" placeholder="" [(ngModel)]="name">
                    <mat-hint align="start"><strong></strong> </mat-hint>
                    <mat-hint align="end">{{nameInput.value.length}} / 100</mat-hint>
                </mat-form-field>                
            
            <button mat-raised-button (click)="exit()" color="warn" style="margin:5px" i18n>Verwerfen</button>
            <button mat-raised-button (click)="save()" color="success" style="margin:5px" i18n>Anlegen</button>
        </mat-card-content>
        </mat-card>
        <mat-card-actions>            
        </mat-card-actions>
    </div>
</div>