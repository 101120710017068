import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  private defaultTitle = 'Dashboard';
  private titleSubject: BehaviorSubject<string> = new BehaviorSubject(this.defaultTitle);
  public title: Observable<string>;


  constructor(private titleService: Title) { 
    this.title = this.titleSubject.asObservable();
  }

  public setTitle(title: string) {
    this.titleSubject.next(title);
  }

}
