<mat-card>  
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <img src="/assets/logo2.jpg" alt="sugi" width="150px">
        <br><br>
        <!--<h2>Waagen NFC Webportal</h2> -->
        <h3 i18n>Login zurücksetzen</h3>
        <p class="alert alert-success" *ngIf="loginSend" i18n>
            Falls deine E-Mail im System registriert ist, hast du per E-Mail deinen Loginnamen sowie einen Passwort zurücksetzen Link erhalten!
        </p>
        <mat-form-field class="full-width-input">
          <input matInput autocomplete="off" type="email" placeholder="E-Mail" formControlName="email" required>
          <mat-error i18n>
            Bitte gib einen gültige E-Mail Adresse ein ein!
          </mat-error>
        </mat-form-field>
        <button mat-raised-button color="primary" i18n>Login-Daten zusenden</button>
      </form>
      <button mat-raised-button color="accent" (click)="back()" style="margin:5px" i18n>zurück</button>
    </mat-card-content>
  </mat-card>  