<div class="content">
    <div fxLayout="row wrap">      
        <mat-card class="overview-card">
            <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
                <!-- <p class="loading">Loading...</p> -->
            </ngx-spinner>
            <mat-card-header>
                <mat-card-title i18n>Produkt bearbeiten</mat-card-title>
            </mat-card-header>
            <mat-card-content class="overview-card-content">
                <span i18n>ID</span>:<br>   
                <span>{{ id }}</span><br> 
                <br>                
                <mat-form-field class="example-full-width">
                <mat-label><span i18n>Name</span>:</mat-label>
                    <input matInput #nameInput maxlength="100" placeholder="" [(ngModel)]="name">
                    <mat-hint align="start"><strong></strong> </mat-hint>
                    <mat-hint align="end">{{nameInput.value.length}} / 100</mat-hint>
                </mat-form-field>
                <br>                
                <mat-form-field class="example-full-width">
                <mat-label><span i18n>Kommentar</span>:</mat-label>
                    <input matInput #commentInput maxlength="500" placeholder="" [(ngModel)]="comment">
                    <mat-hint align="start"><strong></strong> </mat-hint>
                    <mat-hint align="end">{{commentInput.value.length}} / 500</mat-hint>
                </mat-form-field>                
                <br>                
                <mat-form-field class="example-full-width">
                <mat-label><span i18n>Tara</span>:</mat-label>
                    <input matInput #cardidInput maxlength="30" placeholder="" type="number" [(ngModel)]="tara">
                    <span matSuffix>kg</span>
                    <mat-hint align="start"><strong i18n>Gewicht des Kasten/Verpackung</strong> </mat-hint>
                </mat-form-field>
                <br>
                <mat-form-field class="example-full-width">
                <mat-label><span i18n>Bezahlung</span>:</mat-label>
                    <input matInput #cardidInput maxlength="30" placeholder="" type="number" [(ngModel)]="price">
                    <span matSuffix>€/kg</span>
                    <mat-hint align="start"><strong></strong></mat-hint>
                </mat-form-field>

            <button mat-raised-button (click)="exit()" color="warn" style="margin:5px" i18n>Verwerfen</button>
            <button mat-raised-button (click)="save()" color="success" style="margin:5px" i18n>Speichern</button>
        </mat-card-content>
        </mat-card>
        <mat-card-actions>            
        </mat-card-actions>
    </div>
</div>