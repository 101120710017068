import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from "../api/auth.service";

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {

  form: FormGroup;
  public loginInvalid: boolean;
  public serverError: boolean;
  private formSubmitAttempt: boolean;
  private returnUrl: string;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private api: AuthService, private titleService: Title,) { 
    titleService.setTitle("sugi - Login");
    document.body.style.background = '#19002c';
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard/home';

    this.form = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });

    this.api.loadData();
    if(this.api.isConnected())
    {
      this.router.navigate([this.returnUrl]);
    }
  }

  async pwReset() 
  {
    await this.router.navigate(['/reset']);
  }
  
  async onSubmit() {

    if(!this.form.valid)
    {
      return;
    }

    this.form.disable();

    this.loginInvalid = false;
    this.serverError = false;
    this.formSubmitAttempt = false;

    try {
      const username = this.form.get('username').value;
      const password = this.form.get('password').value;

      if(password == null || username == null)
      {
        this.loginInvalid = true;
        this.form.enable();
        return;
      }

      // TODO do login checking here!
      if(await this.api.login(username, password))
      {
        await this.router.navigate([this.returnUrl]);
      }
      else
      {
        this.form.get("username").setValue(username);
        this.form.get("password").setValue("");
        this.loginInvalid = true;
        this.form.enable();
      }
    } catch (err) {
      this.form.get("password").setValue("");
      this.form.enable();
      this.serverError = true;
    }
  }
}
