<div class="content">
    <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <!-- <p class="loading">Loading...</p> -->
    </ngx-spinner>
    <!-- <div fxLayout="row wrap"> -->
        <div class="mat-elevation-z8" style="padding: 16px; margin-bottom: 16px;">
            <mat-tab-group [(selectedIndex)]="currentTab" animationDuration="250ms">
                <mat-tab label="Erntehelfer" i18n-label> 
                    <mat-form-field appearance="fill" style="width: 300px; margin-top: 15px;">
                        <mat-label><span i18n>Erntehelfer</span>:</mat-label>
                        <mat-select [formControl]="selectedPicker" (selectionChange)="newPickerSelected($event)" #selectPicker>                          
                          <mat-option *ngFor="let picker of Pickers"  [value]="picker.id">{{picker.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                </mat-tab>
                <mat-tab label="Produkt" i18n-label> 
                    <mat-form-field appearance="fill" style="width: 300px; margin-top: 15px;">
                        <mat-label><span i18n>Produkte</span>:</mat-label>
                        <mat-select [formControl]="selectedProducts" #selectProduct>                          
                        <mat-option *ngFor="let product of Products"  [value]="product.id">{{product.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                </mat-tab>                    
            </mat-tab-group>                  
        </div>

    <div fxLayout="row wrap">

        <mat-card class="overview-card" *ngIf="currentTab == 0 && selectedPicker.value > 0">
            <mat-card-header>
                <mat-card-title i18n>Ernte letzten 7 Tage vom Erntehelfer</mat-card-title>
            </mat-card-header>
            <mat-card-content class="overview-card-content">
                <ngx-charts-area-chart-stacked
                [view]="[480, 300]"
                [legend]=true
                legendPosition="below"
                [showXAxisLabel]=true
                [showYAxisLabel]=true
                [xAxis]=true
                [yAxis]=true
                xAxisLabel="Tag"
                i18n-xAxisLabel
                yAxisLabel="kg"
                [timeline]=false
                [xAxisTickFormatting]="Picker7DayGraphXTicks"
                [results]="Picker7DayGraph">
                <ng-template #seriesTooltipTemplate let-model="model">
                    <span class="tooltip-label">{{Picker7DayWorkingHoursXTicks2(model[0].name) + " - " + Picker7DayGraphXTicks(model[0].name)}} </span>
                        <div class="area-tooltip-container">
                        <div *ngFor="let entry of model" class="tooltip-item">
                            <span class="tooltip-item-color" [style.background-color]="entry.color"></span>
                            {{entry.series}} : {{entry.value}} kg
                        </div>         
                    </div>                 
                  </ng-template>
                </ngx-charts-area-chart-stacked>
            </mat-card-content>    
        </mat-card>


        <mat-card class="overview-card" *ngIf="currentTab == 0 && selectedPicker.value > 0">
            <mat-card-header>
                <mat-card-title i18n>Arbeitszeiten letzten 7 Tage vom Erntehelfer</mat-card-title>
            </mat-card-header>
            <mat-card-content class="overview-card-content">
                <ngx-charts-bubble-chart
                [view]="[480, 300]"
                [legend]=false
                [showXAxisLabel]=true
                [showYAxisLabel]=true
                [xAxis]=true
                [yAxis]=true
                xAxisLabel="Tag"
                i18n-xAxisLabel
                yAxisLabel="Stunden"
                i18n-yAxisLabel
                [yAxisTicks]="[6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]"
                [yScaleMin]=6
                [yScaleMax]=20
                [xAxisTickFormatting]="Picker7DayWorkingHoursXTicks"
                [results]=Picker7DayWorkingHours>
                <ng-template #tooltipTemplate let-model="model">
                    <span class="tooltip-label"><span i18n>Datenpunkt</span>: {{Picker7DayGraphXTicks(model.timestamp) + " - " + model.y}} <span i18n>Uhr</span></span>
                    <span class="tooltip-val">
                    <span i18n>Picks</span>: {{model.picks}} <br>
                    <span i18n>Bezahlung</span>:  {{model.money}} € <br>
                    <span i18n>Gewicht</span>:  {{model.totalKG}} kg
                    </span>
                    <!-- <pre>{{model|json}}</pre> -->
                  </ng-template>
                </ngx-charts-bubble-chart>
            </mat-card-content>    
        </mat-card>


        <mat-card class="overview-card" *ngIf="currentTab == 0 && selectedPicker.value > 0">
            <mat-card-header>
                <mat-card-title i18n>Ernte in kg der letzten 7 Tage vom Erntehelfer</mat-card-title>
            </mat-card-header>
            <mat-card-content class="overview-card-content">
                <ngx-charts-pie-chart
                [view]="[480, 300]"
                [labels]=true         
                [tooltipText]="pieTooltipText"
                [results]=dataPiChartPickerKG>
                </ngx-charts-pie-chart>
            </mat-card-content>    
        </mat-card>

        <mat-card class="overview-card" *ngIf="currentTab == 0 && selectedPicker.value > 0">
            <mat-card-header>
                <mat-card-title i18n>Erlös der letzten 7 Tage vom Erntehelfer</mat-card-title>
            </mat-card-header>
            <mat-card-content class="overview-card-content">
                <ngx-charts-pie-chart
                [view]="[480, 300]"
                [labels]=true         
                [tooltipText]="pieTooltipText2"
                [results]=dataPiChartPickerMoney>
                </ngx-charts-pie-chart>
            </mat-card-content>    
        </mat-card>


        <mat-card class="overview-card" *ngIf="currentTab == 0 && selectedPicker.value > 0">
            <mat-card-header>
                <mat-card-title i18n>Pflück-Ereignisse der letzten 7 Tage vom Erntehelfer</mat-card-title>
            </mat-card-header>
            <mat-card-content class="overview-card-content">
                <ngx-charts-pie-chart
                [view]="[480, 300]"
                [labels]=true         
                [tooltipText]="pieTooltipText3"
                [results]=dataPiChartPickerEvents>
                </ngx-charts-pie-chart>
            </mat-card-content>    
        </mat-card>

        <mat-card class="overview-card" *ngIf="currentTab == 1 && selectedProducts.value > 0">
            <mat-card-header>
                <mat-card-title i18n>Jahresrückblick</mat-card-title>
            </mat-card-header>
            <mat-card-content class="overview-card-content">
                <ngx-charts-heat-map
                [view]="view"
                [scheme]="colorScheme"
                [legend]="legend"
                [xAxisTickFormatting]="calendarAxisTickFormatting"
                [xAxis]="xAxis"
                [yAxis]="yAxis"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [results]="multi"
                [innerPadding]="innerPadding"
                [trimXAxisTicks]="trimXAxisTicks"
                [trimYAxisTicks]="trimYAxisTicks"
                [gradient]="gradient"
                [tooltipText]="calendarTooltipText"
                >
                </ngx-charts-heat-map>
            </mat-card-content>    
        </mat-card>
    </div> 
</div>