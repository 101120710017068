import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PickersService } from 'src/app/api/pickers.service';
import { TitleService } from 'src/app/services/title.service';


export interface ResultAdd {
  id : number;
  success : boolean;
}

@Component({
  selector: 'app-picker-add',
  templateUrl: './picker-add.component.html',
  styleUrls: ['./picker-add.component.css']
})
export class PickerAddComponent implements OnInit {

  name: string;

  constructor(private route: ActivatedRoute, private router: Router, private pickers : PickersService, private titleService: TitleService) { 
    this.titleService.setTitle($localize`Erntehelfer hinzufügen`);
  }

  ngOnInit(): void {
    
  }

  save(): void {
    this.pickers.addPicker(this.name).then((data) => this.onDataSaved(data));
  }

  async onDataSaved(data){
    let result = <ResultAdd>data;

    await this.router.navigate(['./dashboard/pickeredit', result.id ]);
  }

  async exit() {
    await this.router.navigate(['./dashboard/picker']);  
  }

}
