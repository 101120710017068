<div class="content">
    <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <!-- <p class="loading">Loading...</p> -->
    </ngx-spinner>

    <div class="mat-elevation-z8" style="padding: 16px; margin-bottom: 16px;">
        <mat-form-field appearance="fill" style="width: 300px; margin-top: 15px;">
            <mat-label><span i18n>Erntehelfer</span></mat-label>
            <mat-select [formControl]="selectedPicker" #selectPicker>                          
                <mat-option *ngFor="let picker of Pickers"  [value]="picker.id">{{picker.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label i18n>Monat und Jahr</mat-label>
            <input matInput [matDatepicker]="dp" [formControl]="date">
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp
                            startView="multi-year"
                            (yearSelected)="chosenYearHandler($event)"
                            (monthSelected)="chosenMonthHandler($event, dp)"
                            panelClass="example-month-picker">
            </mat-datepicker>
          </mat-form-field>          

          <button mat-raised-button (click)="updateView()" color="success" style="margin:5px" i18n>Monatsbericht anzeigen</button>

          <button mat-raised-button (click)="downloadCSV()" color="primary" style="margin:5px" i18n>Als CSV herunterladen</button>

          <button mat-raised-button (click)="downloadXLSX()" color="primary" style="margin:5px" i18n>Als XLSX herunterladen</button>
    </div>

    <div class="mat-elevation-z8" style="padding: 16px; margin-bottom: 16px;" *ngIf="viewResults == true">
        <span i18n>Hier sehen sie den Monatsbreicht von</span> <b> {{getPickerName(currentPicker)}} </b> <span i18n>aus dem Monat</span> <b> {{currentPickerDate}}</b>.
        <br>
        <span i18n>Verdienst im Monat</span>: <b>{{getFormatMoney(currentMoney)}}</b>
    </div>

    <div class="mat-elevation-z8" *ngIf="viewResults == true">


        <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
          <!-- <p class="loading">Loading...</p> -->
        </ngx-spinner>
  
        <table mat-table [dataSource]="dataSource" matSort>        
      
          <ng-container matColumnDef="productid">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>ID</th>
            <td mat-cell *matCellDef="let row" [ngClass]="'w-150'"> {{row.productid}} </td>
          </ng-container>

          <ng-container matColumnDef="productname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Name</th>
            <td mat-cell *matCellDef="let row" [ngClass]="'w-150'"> {{row.productname}} </td>
          </ng-container>

          <ng-container matColumnDef="events">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Wägungen</th>
            <td mat-cell *matCellDef="let row"> {{row.pickevents}} </td>
          </ng-container>

          <ng-container matColumnDef="totalg">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Gesamt</th>
            <td mat-cell *matCellDef="let row"> {{getFormatWeight(row.totalg)}} </td>
          </ng-container>

          <ng-container matColumnDef="money">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Verdienst</th>
            <td mat-cell *matCellDef="let row"> {{getFormatMoney(row.money)}} </td>
          </ng-container>
      
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4" i18n>Es gibt keinen Einträge zum ausgewählten Filter</td>
          </tr>
        </table>
    
      <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]"></mat-paginator>
    </div>
</div>