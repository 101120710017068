<div class="content">
  <div class="mat-elevation-z8" style="padding: 16px; margin-bottom: 16px;">
    <p i18n>Hier sind alle Zeiterfassung Events im Rohformat aufgelistet</p>
  </div>

  <div class="mat-elevation-z8">
    <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
      <!-- <p class="loading">Loading...</p> -->
    </ngx-spinner>
    
    <table mat-table [dataSource]="dataSource" matSort style="width: 100%">
  
      <ng-container matColumnDef="uuid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>UUID</th>
        <td mat-cell *matCellDef="let row"> {{row.uuid}} </td>
      </ng-container>
  
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Pflücker</th>
        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Aktion</th>
        <td mat-cell *matCellDef="let row"> {{getActionName(row.event)}} </td>
      </ng-container>
  
      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Zeit</th>
        <td mat-cell *matCellDef="let row"> {{row.time}} </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4" i18n>Keine Zeitevents gefunden!</td>
      </tr>
    </table>    
  <mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
</div>
</div>