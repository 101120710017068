import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ScalesService } from 'src/app/api/scales.service';
import { TitleService } from 'src/app/services/title.service';

export interface ScaleData {
  id: number;
  name: string;
  comment: string;
  pin: string;
}

@Component({
  selector: 'app-scales-edit',
  templateUrl: './scales-edit.component.html',
  styleUrls: ['./scales-edit.component.css']
})
export class ScalesEditComponent implements OnInit {

  id: number;
  name: string;
  comment: string;
  pin: string;

  sub: any;

  constructor(private route: ActivatedRoute, private router: Router,  private titleService: TitleService, private scales : ScalesService, private spinner: NgxSpinnerService) { 
    this.titleService.setTitle($localize`Waage bearbeiten`);

    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id']; // (+) converts string 'id' to a number

      this.spinner.show();
      this.scales.getScaleInfo(this.id).then((data) => this.onData(data));
  });
  }

  onData(data) {
    let dataParsed = <ScaleData>data;

    this.name = dataParsed.name;
    this.comment = dataParsed.comment;
    this.pin = dataParsed.pin;      

    this.spinner.hide();
  }

  ngOnInit(): void {
  }

  save(): void {
    this.scales.saveScale(this.id, this.name, this.comment, this.pin).then((data) => this.onDataSaved(data));
  }

  async onDataSaved(data){
    await this.router.navigate(['./dashboard/scales']);
  }

  async exit() {
    await this.router.navigate(['./dashboard/scales']);  
  }


}
