import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from "@angular/common/http";
import { HttpParams } from "@angular/common/http";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class TimeEventsService {

  constructor(private http : HttpClient, private auth : AuthService) { }

  async getAllRawTimeEvents()
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-API-KEY': this.auth.getApiKey()
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return await this.http.get(this.auth.getBasePath() + "api/v1/web/time/allRaw", requestOptions).toPromise();
  }

  async getTimeEventStats()
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-API-KEY': this.auth.getApiKey()
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return await this.http.get(this.auth.getBasePath() + "api/v1/web/time/stats", requestOptions).toPromise();
  }
}
