import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GeneralService } from 'src/app/api/general.service';
import { TitleService } from 'src/app/services/title.service';
import { convertToParamMap} from '@angular/router';
import { MatSelect } from '@angular/material/select';
import { NgxSpinnerService } from 'ngx-spinner';
import { PickersService } from 'src/app/api/pickers.service';

const monthName = new Intl.DateTimeFormat('en-us', { month: 'short' });
const weekdayName = new Intl.DateTimeFormat('en-us', { weekday: 'short' });

export interface NameMapping {
  id : number;
  name : string;
}

export interface APINameIDMap {
  pickers: NameMapping[],
  scales: NameMapping[],
  products: NameMapping[]
}

export interface PickPerDay {
  day: string,
  productid: number,
  productname: string,
  totalg: number,
  pickevents: number,
  money: number
}

export interface PickPerHour{
  hour: string,
  totalg: number,
  pickevents: number,
  money: number
}

export interface ProductReport{
  productid: number,
  productname: string,
  totalg: number,
  pickevents: number,
  money: number
}

export interface PickerStats{
  pickPerDay : PickPerDay[],
  pickPerHour : PickPerHour[],
  productReport : ProductReport[]
}

@Component({
  selector: 'app-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.css']
})
export class StatisticComponent implements OnInit {

  selectedPicker = new FormControl();
  @ViewChild('selectProduct') private selectProductViewChild;

  selectedScales = new FormControl();

  selectedProducts = new FormControl();
  @ViewChild('selectPicker') private selectPickerViewChild;

  Pickers : NameMapping[];
  Products : NameMapping[];
  Scales : NameMapping[];

  currentTab : number = 0;

  sub: any;
  
  multi: any[];
  multi2: any[];
  view: any[] = [1100, 200];

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Country';
  yAxisLabel: string = 'Year';

  innerPadding = "10%";
  trimXAxisTicks = true;
  trimYAxisTicks = true;
  gradient = false;

  colorScheme = {
    domain: ['#647c8a',
    '#3f51b5',
    '#2196f3',
    '#00b862',
    '#afdf0a',
    '#a7b61a',
    '#f3e562',
    '#ff9800',
    '#ff5722',
    '#ff4514']
  };

  calendarTooltipText(c): string {
    return `
      <span class="tooltip-label">${c.label} • ${c.cell.date.toLocaleDateString()}</span>
      <span class="tooltip-val">${c.data} kg</span>
    `;
  }

  calendarAxisTickFormatting(mondayString: string) {
    const monday = new Date(mondayString);
    const month = monday.getMonth();
    const day = monday.getDate();
    const year = monday.getFullYear();
    const lastSunday = new Date(year, month, day - 1);
    const nextSunday = new Date(year, month, day + 6);
    return lastSunday.getMonth() !== nextSunday.getMonth() ? monthName.format(nextSunday) : '';
  }

  getCalendarData(offset): any[] {
    // today
    const now = new Date();
    const todaysDay = now.getDate();
    const thisDay = new Date(now.getFullYear(), now.getMonth(), todaysDay);

    // Monday
    const thisMonday = new Date(thisDay.getFullYear(), thisDay.getMonth(), todaysDay - thisDay.getDay() + 1);
    const thisMondayDay = thisMonday.getDate();
    const thisMondayYear = thisMonday.getFullYear();
    const thisMondayMonth = thisMonday.getMonth();

    // 52 weeks before monday
    const calendarData = [];
    const getDate = d => new Date(thisMondayYear, thisMondayMonth, d);
    for (let week = -52; week <= 0; week++) {
      const mondayDay = thisMondayDay + week * 7;
      const monday = getDate(mondayDay);

      // one week
      const series = [];
      for (let dayOfWeek = 7; dayOfWeek > 0; dayOfWeek--) {
        const date = getDate(mondayDay - 1 + dayOfWeek);

        // skip future dates
        if (date > now) {
          continue;
        }

        // value
        const value = dayOfWeek < 6 ? (date.getMonth() + 1 + offset) % 12 : 0;

        series.push({
          date,
          name: weekdayName.format(date),
          value
        });
      }

      calendarData.push({
        name: monday.toString(),
        series
      });
    }

    return calendarData;
  }

 

  pieTooltipText({ data }) {
    const label = data.name;
    const val = data.value;

    return `
      <span class="tooltip-label">${label}</span>
      <span class="tooltip-val">${val} kg</span>
    `;
  }

  pieTooltipText2({ data }) {
    const label = data.name;
    const val = data.value;

    return `
      <span class="tooltip-label">${label}</span>
      <span class="tooltip-val">${val} €</span>
    `;
  }

  pieTooltipText3({ data }) {
    const label = data.name;
    const val = data.value;

    return `
      <span class="tooltip-label">${label}</span>
      <span class="tooltip-val">${val}</span>
    `;
  }

  Picker7DayGraphXTicks(val){    
   let date = new Date(val);   
   return date.getDate() + "." + (date.getMonth() + 1);
  }

  Picker7DayWorkingHoursXTicks(val) {
    switch(val)
      {
        case 0:
           return $localize`:Montag in Kurzform:Mo`;
 
         case 1:
           return $localize`:Dienstag in Kurzform:Di`;
 
         case 2:
           return $localize`:Mittwoch in Kurzform:Mi`;
 
         case 3:
           return $localize`:Donnerstag in Kurzform:Do`;
 
         case 4:
           return $localize`:Freitag in Kurzform:Fr`;
       
         case 5:
           return $localize`:Samstag in Kurzform:Sa`;
 
         case 6:
           return $localize`:Sonntag in Kurzform:So`;
      }
   
    let date = new Date(val);   
    return date.getDate() + "." + (date.getMonth() + 1);
  }

  Picker7DayWorkingHoursXTicks2(val) {
    switch(new Date(val).getDay())
      {
        case 0:
           return $localize`:Montag in Kurzform:Mo`;
 
         case 1:
           return $localize`:Dienstag in Kurzform:Di`;
 
         case 2:
           return $localize`:Mittwoch in Kurzform:Mi`;
 
         case 3:
           return $localize`:Donnerstag in Kurzform:Do`;
 
         case 4:
           return $localize`:Freitag in Kurzform:Fr`;
       
         case 5:
           return $localize`:Samstag in Kurzform:Sa`;
 
         case 6:
           return $localize`:Sonntag in Kurzform:So`;
      }
  }

  dataPiChartPickerKG : any[] = [];
  dataPiChartPickerMoney : any[] = [];
  dataPiChartPickerEvents : any[] = [];
  Picker7DayGraph : any[] = [];
  Picker7DayWorkingHours : any[] = [];

  newPickerSelected(event)
  {
    this.spinner.show();
    this.pickerapi.getStats(this.selectedPicker.value).then((data) => this.onDataMappingPickers(data));
  }

  onDataMappingPickers(data)
  {
    let rawData = <PickerStats>data;


    this.dataPiChartPickerMoney = [];
    this.dataPiChartPickerKG = [];
    this.dataPiChartPickerEvents = [];

    rawData.productReport.forEach(element => {
      this.dataPiChartPickerEvents.push({
        "name" : element.productname,
        "value" : element.pickevents
      });

      this.dataPiChartPickerKG.push({
        "name" : element.productname,
        "value" : element.totalg / 1000
      });

      this.dataPiChartPickerMoney.push({
        "name" : element.productname,
        "value" : (+element.money).toFixed(2) 
      });
    });


    this.Picker7DayGraph = []
    
    rawData.pickPerDay.forEach(element => {

      let name = element.productname;

      // check if there is already a series with this name      
      let series = this.Picker7DayGraph.find(element2 => {
        if(element2['name'] == name)
        {
          return true;
        }
        return false;
      });

      if(series == undefined)
      {
        series = {
          "name" : element.productname,
          "series" : []
        };

        this.Picker7DayGraph.push(series);
      }
      
      let pointTime = new Date(element.day);

      series["series"].push({
        "name" : new Date(pointTime.getFullYear() + "-" +  (pointTime.getMonth() + 1) + "-" + pointTime.getDate() + " 12:00:01").getTime(),
        "value" : +(element.totalg / 1000).toFixed(2)
      })

    });


    let HourSeries = [];

    rawData.pickPerHour.forEach(element => {

      let pointTime = new Date(element.hour);

      let hourTime = new Date(pointTime.getFullYear() + "-" +  (pointTime.getMonth() + 1) + "-" + pointTime.getDate() + " " + pointTime.getHours + ":30:01");
      let dayTime = new Date(pointTime.getFullYear() + "-" +  (pointTime.getMonth() + 1) + "-" + pointTime.getDate() + " 12:30:01");

      let dateName = "";
      switch(dayTime.getDay())
      {
        case 0:
          dateName = $localize`:Montag in Kurzform:Mo`;
          break;

        case 1:
          dateName = $localize`:Dienstag in Kurzform:Di`;
          break;

        case 2:
          dateName = $localize`:Mittwoch in Kurzform:Mi`;
          break;

        case 3:
          dateName = $localize`:Donnerstag in Kurzform:Do`;
          break;

        case 4:
          dateName = $localize`:Freitag in Kurzform:Fr`;
          break;
      
        case 5:
          dateName = $localize`:Samstag in Kurzform:Sa`;
          break;

        case 6:
          dateName = $localize`:Sonntag in Kurzform:So`;
          break;
      }

      HourSeries.push({
        "name" : pointTime.getDay(),
        "x" : pointTime.getDay(),
        "y" : pointTime.getHours(),
        "r" : 20,
        "picks" : element.pickevents,
        "money" : (+element.money).toFixed(2),
        "totalKG" : (element.totalg / 1000).toFixed(2),
        "timestamp" : dayTime.getTime()
      });
    });

    this.Picker7DayWorkingHours = [
      {
        "name": $localize`Arbeitszeiten`,
        "series": HourSeries
      }
    ];

    console.log(this.Picker7DayWorkingHours);
    


    this.spinner.hide();
  }

  constructor(private titleService: TitleService, private generalAPI : GeneralService, private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService, private pickerapi : PickersService) 
  { 

    this.spinner.show();

    this.titleService.setTitle($localize`Übersicht Statistik`);

      this.multi = this.getCalendarData(0);
      this.multi2 = this.getCalendarData(5);

      generalAPI.getAllIDNameMappings().then((data) => this.onDataMapping(data));

      return;
  }

  ngOnInit(): void {
    
  }


  onDataMapping(data) : void {
    let rawData = <APINameIDMap>data;
    this.Pickers = rawData.pickers;
    this.Products = rawData.products;
    this.Scales = rawData.scales;

    this.spinner.hide();

    this.sub = this.route.params.subscribe(params => {       
      let paramID = params['id']; 
      let paramType = +params['type'];

      if(paramID != undefined && paramType != undefined)
      {
        if(paramType == 0)
        {
          this.selectedPicker.setValue(paramID);
          this.currentTab = 0;
        } 
        else if(paramType == 1)
        {
          this.selectedProducts.setValue(paramID);
          this.currentTab = 1;
        } 
      }   
   });

  }

}
