import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneralService } from 'src/app/api/general.service';
import { TitleService } from 'src/app/services/title.service';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { saveAs } from 'file-saver';
import { MatTableDataSource } from '@angular/material/table';
import { PickersService } from 'src/app/api/pickers.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';
const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface NameMapping {
  id : number;
  name : string;
}
export interface APINameIDMap {
  pickers: NameMapping[],
  scales: NameMapping[],
  products: NameMapping[]
}
export interface Picks {
  productid : number;
  productname : string;
  totalg : number;
  pickevents : number;
  money : number;
}

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class ExportComponent implements OnInit {

  displayedColumns: string[] = ['productid', 'productname', 'events', 'totalg', 'money'];
  dataSource: MatTableDataSource<Picks>;
  rawData : Picks[]

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selectedPicker = new FormControl();
  @ViewChild('selectProduct') private selectProductViewChild;
  Pickers : NameMapping[];
  

  viewResults : boolean = false;
  currentPicker : string;
  currentPickerDate : string;    
  selectedPickerDate : string;
  currentMoney : number;

  constructor(private titleService: TitleService, private generalAPI : GeneralService, private pickerAPI : PickersService, private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService) { }


  updateView() : void {        
    let ctrlValue = this.date.value;    

    this.currentPicker = this.selectedPicker.value;

    if(this.currentPicker == null || this.currentPicker.length < 0)
    {
        return;
    }      

    this.spinner.show(); 
    this.currentPickerDate = ((ctrlValue.toDate().getMonth() + 1) < 10 ? "0" : "") + (ctrlValue.toDate().getMonth() + 1) + "." + ctrlValue.toDate().getFullYear();
    this.pickerAPI.getMonthReport(this.currentPicker, this.currentPickerDate).then((data) => this.onDataReport(data));
  }

  onDataReport(data) : void {
    this.viewResults = true;
    this.rawData = <Picks[]>data;    

    console.log(this.rawData);
    

    this.currentMoney = 0;

    if(this.rawData)
    {
      this.rawData.forEach(element => {
        this.currentMoney += (+element.money);
      });
    }

    this.dataSource = new MatTableDataSource(this.rawData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.spinner.hide();
   }

  downloadCSV() : void {
    const filename = "sugi " + $localize`Monatsbericht` + " - " + this.getPickerName(this.currentPicker) + " - " + this.currentPickerDate;

    this.spinner.show();
    this.pickerAPI.getMonthReportCSV(this.currentPicker, this.currentPickerDate).subscribe((resp: any) => {
      const blob = new Blob([resp], { type: 'application/octet-stream' });
      this.spinner.hide();
      saveAs(blob, filename + ".csv");
   }); 
  }

  downloadXLSX() : void {
    const filename = "sugi " + $localize`Monatsbericht` + " - " + this.getPickerName(this.currentPicker) + " - " + this.currentPickerDate;

    this.spinner.show();
    this.pickerAPI.getMonthReportXLSX(this.currentPicker, this.currentPickerDate).subscribe((resp: any) => {
      const blob = new Blob([resp], { type: 'application/octet-stream' });
      this.spinner.hide();
      saveAs(blob, filename + ".xlsx");
   }); 
  }

  ngOnInit(): void {
    this.spinner.show();
    this.titleService.setTitle($localize`Daten exportieren`);

    this.generalAPI.getAllIDNameMappings().then((data) => this.onDataMapping(data));
  }

  onDataMapping(data) : void {
    let rawData = <APINameIDMap>data;
    this.Pickers = rawData.pickers;
    this.spinner.hide();
   }

   date = new FormControl(moment());

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);    
    datepicker.close();

    console.log(Object.keys(ctrlValue));
    
    this.selectedPickerDate = ((ctrlValue.toDate().getMonth() + 1) < 10 ? "0" : "") + (ctrlValue.toDate().getMonth() + 1) + "." + ctrlValue.toDate().getFullYear();
  }

  getFormatWeight(weight) : string{

    if(weight == null || weight == undefined)
    {
      return "0 kg";
    }

    let weightG : number = weight;
    let weightKG = weightG / 1000;

    return weightKG.toFixed(2) + " kg";
  }

  getFormatMoney(money) : string{

    if(money == null || money == undefined)
    {
      return "0,00 €";
    }

    let moneyCent : number = money;
    let moneyEuro = moneyCent / 100;

    return moneyEuro.toFixed(2) + " €";
  }

  getPickerName(id) : string {
    for(let data of this.Pickers)
    {
      if(data.id == id)
        return data.name;
    }

    return "unkown (" + id + ")";
  }

}
