<div class="content">
    <div fxLayout="row wrap">      
        <mat-card class="overview-card">
            <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
            <!-- <p class="loading">Loading...</p> -->
            </ngx-spinner>

            <mat-card-header>
                <mat-card-title i18n>Details Endgerät</mat-card-title>
            </mat-card-header>
            <mat-card-content class="overview-card-content">
                <span i18n>ID</span>:<br>   
                <span>{{ id }}</span><br>
                <br>
                <span i18n>Name</span>:<br>
                <span>{{ name }}</span><br>   
                <br>
                <span i18n>Pin</span>:<br>
                <span>{{ pin }}</span><br>   
                <br>
                <span i18n>Kommentar</span>:<br>
                <span>{{ comment }}</span><br>   
                <br>
                <span i18n>Modus</span>:<br>
                <span *ngIf="scalefeature">Waagemodus</span><br>   
                <span *ngIf="timefeature">Zeiterfassung</span><br>   
                <br>
                <span i18n>Verbunden</span>:<br>
                <span>{{ connected }}</span><br>   
                <br>
                <span *ngIf="showQR" i18n>Scanne den folgendne QR Code mit der sugi-App, um das Endgerät mit dem sugi-Server zu verbinden.</span>   
                <div *ngIf="showQR" style="height: 16px;"></div>             
                <span *ngIf="showQR" style="text-align: center;"><ngx-kjua [text]="qr"></ngx-kjua></span>
            </mat-card-content>    
        </mat-card>
    </div>
</div>
