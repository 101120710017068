import { Component, OnInit, OnDestroy  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxSpinnerService } from 'ngx-spinner';
import { ScalesService } from 'src/app/api/scales.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-scales-view',
  templateUrl: './scales-view.component.html',
  styleUrls: ['./scales-view.component.css']
})
export class ScalesViewComponent implements OnInit {

  id: number;
  name: string;
  comment: string;
  pin: string;
  connected: boolean = false;
  scalefeature: boolean = false;
  timefeature: boolean = false;
  qr: string;
  showQR : boolean = false;
  private sub: any;


  constructor(private scale : ScalesService, private route: ActivatedRoute, private titleService: TitleService, private spinner: NgxSpinnerService) { 
    this.titleService.setTitle($localize`Detailansicht Waage`);
  }

  ngOnInit() {    

    this.sub = this.route.params.subscribe(params => {
       this.id = +params['id']; // (+) converts string 'id' to a number

       this.spinner.show();
       this.scale.getScaleInfo(this.id).then((data) => this.onData(data));
    });
  }

  onData(data) : void {
    this.spinner.hide();
    this.name = data['name'];
    this.pin = data['pin'];
    this.comment = data['comment'];
    this.connected = data['connected'] == 1;
    this.scalefeature = data['scalefeature'] == 1;
    this.timefeature = data['timefeature'] == 1;
    this.qr = JSON.stringify({
      secret : data['secret'],
      terminalID : data['id'],
      apiEndpoint : data['apiEndpoint']
    });
    this.showQR = !this.connected;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
