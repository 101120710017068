import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(private http : HttpClient, private auth : AuthService) { }
  
  async getAllIDNameMappings()
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-API-KEY': this.auth.getApiKey()
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return await this.http.get(this.auth.getBasePath() + "api/v1/web/general/nameidmap", requestOptions).toPromise();
  }
}
