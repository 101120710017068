import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PickersService {

  constructor(private http : HttpClient, private auth : AuthService) { }


  async getAllPickers()
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-API-KEY': this.auth.getApiKey()
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return await this.http.get(this.auth.getBasePath() + "api/v1/web/pickers/all", requestOptions).toPromise();
  }


  async getPickerByID(pickerID)
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-API-KEY': this.auth.getApiKey()
    }

    var params = new HttpParams()
      .set('id', pickerID);

    const requestOptions = {      
      params: params,                                                                                                                                                                           
      headers: new HttpHeaders(headerDict), 
    };

    return await this.http.get(this.auth.getBasePath() + "api/v1/web/pickers/id", requestOptions).toPromise();
  }

  async savePicker(id, name, comment, cardid, productid)
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-API-KEY': this.auth.getApiKey()
    }

    var params = new HttpParams()
      .set('id', id)
      .set('name', name)
      .set('comment', comment)
      .set('cardid', cardid)
      .set('productid', productid);

    const requestOptions = {      
      params: params,                                                                                                                                                                           
      headers: new HttpHeaders(headerDict), 
    };

    return await this.http.get(this.auth.getBasePath() + "api/v1/web/pickers/save", requestOptions).toPromise();
  }

  async addPicker(name)
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-API-KEY': this.auth.getApiKey()
    }

    var params = new HttpParams()
      .set('name', name);

    const requestOptions = {      
      params: params,                                                                                                                                                                           
      headers: new HttpHeaders(headerDict), 
    };

    return await this.http.get(this.auth.getBasePath() + "api/v1/web/pickers/add", requestOptions).toPromise();
  }


  async getMonthReport(pickerID, month)
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-API-KEY': this.auth.getApiKey()
    }

    var params = new HttpParams()
      .set('picker', pickerID)
      .set('month', month);

    const requestOptions = {      
      params: params,                                                                                                                                                                           
      headers: new HttpHeaders(headerDict), 
    };

    return await this.http.get(this.auth.getBasePath() + "api/v1/web/pickers/monthreport", requestOptions).toPromise();
  }

  getMonthReportCSV(pickerID, month)
  {
    const headerDict = {
      'Content-Type': 'text/csv',
      'Accept': 'text/csv',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-API-KEY': this.auth.getApiKey()      
    }

    var params = new HttpParams()
    .set('picker', pickerID)
    .set('month', month);

    const requestOptions = {     
      params: params,                                                                                                                                                                            
      headers: new HttpHeaders(headerDict),      
      responseType: 'arraybuffer' as 'arraybuffer'
    };

    return this.http.get(this.auth.getBasePath() + "api/v1/web/pickers/monthreportCSV", requestOptions);
  }

  getMonthReportXLSX(pickerID, month)
  {
    const headerDict = {
      'Content-Type': 'text/csv',
      'Accept': 'text/csv',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-API-KEY': this.auth.getApiKey()      
    }
    var params = new HttpParams()
      .set('picker', pickerID)
      .set('month', month);

    const requestOptions = {     
      params: params,                                                                                                                                                                            
      headers: new HttpHeaders(headerDict),      
      responseType: 'arraybuffer' as 'arraybuffer'
    };    

    return this.http.get(this.auth.getBasePath() + "api/v1/web/pickers/monthreportXLSX", requestOptions);
  }


  async getStats(pickerID)
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-API-KEY': this.auth.getApiKey()
    }

    var params = new HttpParams()
      .set('picker', pickerID);

    const requestOptions = {      
      params: params,                                                                                                                                                                           
      headers: new HttpHeaders(headerDict), 
    };

    return await this.http.get(this.auth.getBasePath() + "api/v1/web/pickers/getStats", requestOptions).toPromise();
  }
}
