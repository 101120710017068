import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from "./api/auth.service";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'sugi App';

  constructor(private titleService: Title, private api : AuthService) {
    this.titleService.setTitle("Scaleviewer");
  }

  ngAfterViewInit(): void{
    this.api.loadData();
  }
}
