<div class="content">
  <div class="mat-elevation-z8" style="padding: 16px; margin-bottom: 16px;">
    <button mat-raised-button disabled color="success" style="margin:5px"><ng-container i18n>Neues Endgerät anlegen</ng-container></button>
    <p i18n>Die maximale Anzahl der Endgeräte ist aufgrund des gebuchten Paketes auf 3 beschränkt.</p>
  </div>

  <div class="mat-elevation-z8">
    <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
      <!-- <p class="loading">Loading...</p> -->
    </ngx-spinner>
    
    <table mat-table [dataSource]="dataSource" matSort style="width: 100%">
  
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>ID</th>
        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
      </ng-container>
  
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Name</th>
        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
      </ng-container>
  
      <ng-container matColumnDef="comment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Kommentar</th>
        <td mat-cell *matCellDef="let row"> {{row.comment}} </td>
      </ng-container>

      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Optionen</th>
        <td mat-cell *matCellDef="let row"> 
            <button mat-raised-button (click)="onView(row.id)" color="primary" style="margin:5px" i18n>Details</button>
            <button mat-raised-button (click)="onEdit(row.id)" style="margin:5px" i18n>Bearbeiten</button>
            <!-- <button mat-raised-button (click)="onDelete(row.id)" color="warn" style="margin:5px">Löschen</button> -->
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4" i18n>Keine Waage passt zum Filter!</td>
      </tr>
    </table>    
  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
</div>
</div>