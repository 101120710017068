import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductsService } from 'src/app/api/products.service';
import { TitleService } from 'src/app/services/title.service';

export interface ProductData {
  id: number;
  name: string;
  comment: string;
  tara: number;
  pay: number;
}
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  displayedColumns: string[] = ['id', 'name', 'comment', 'tara', 'pay', 'options'];
  dataSource: MatTableDataSource<ProductData>;
  rawData : ProductData[]

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(private route: ActivatedRoute, private router: Router, private products : ProductsService, private titleService: TitleService, private spinner: NgxSpinnerService) { 
    this.titleService.setTitle($localize`Übersicht Produkte`);

    this.spinner.show();
    products.getAllProducts().then((data) => this.onDataMapping(data));
  }

  onDataMapping(data) {
    this.spinner.hide();
    this.rawData = <ProductData[]>data;
    this.dataSource = new MatTableDataSource(this.rawData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    
  }

  async newProduct() {
    await this.router.navigate(['./dashboard/productadd']);
  }

  async onView(id) {
    await this.router.navigate(['./dashboard/statistic', 1, id ]);
  }

  async onEdit(id) {
    await this.router.navigate(['./dashboard/productedit', id ]);
  }

  async onDelete(id) {
    //await this.router.navigate(['./dashboard/scalesview', id ]);
  }

}
