import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneralService } from 'src/app/api/general.service';
import { PickersService } from 'src/app/api/pickers.service';
import { TitleService } from 'src/app/services/title.service';

export interface PickerData {
  id: number;
  name: string;
  comment: string;
  cardid: string;
  productid: string;
}
export interface NameMapping {
  id : number;
  name : string;
}
export interface APINameIDMap {
  pickers: NameMapping[],
  scales: NameMapping[],
  products: NameMapping[]
}
@Component({
  selector: 'app-picker',
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.css']
})
export class PickerComponent implements OnInit {

  displayedColumns: string[] = ['id', 'name', 'comment', 'cardid', 'productid', 'options'];
  dataSource: MatTableDataSource<PickerData>;
  rawData : PickerData[]

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  Products : NameMapping[];

  constructor(private route: ActivatedRoute, private router: Router, private pickers : PickersService, private titleService: TitleService, private generalAPI : GeneralService, private spinner: NgxSpinnerService) { 
    this.titleService.setTitle($localize`Übersicht Erntehelfer`);
    
    this.spinner.show();
    pickers.getAllPickers().then((data) => this.onDataMapping(data));
    generalAPI.getAllIDNameMappings().then((data) => this.onIDMapping(data));
  }

  onDataMapping(data) {
    this.spinner.hide();

    this.rawData = <PickerData[]>data;
    this.dataSource = new MatTableDataSource(this.rawData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onIDMapping(data) : void {
    let rawData = <APINameIDMap>data;
    this.Products = rawData.products;
  }

  getProductName(id) : string {
    if(id == null && id <= 0)
      return "-/-";

    for(let data of this.Products)
    {
      if(data.id == id)
        return data.name;
    }

    return "unkown (" + id + ")";
  }

  ngOnInit(): void {
    
  }

  async newPicker() {
    await this.router.navigate(['./dashboard/pickeradd']);
  }

  async onView(id) {
    await this.router.navigate(['./dashboard/statistic', 0, id ]);
  }

  async onEdit(id) {
    await this.router.navigate(['./dashboard/pickeredit', id ]);
  }
  
  async onBericht(id) {
    await this.router.navigate(['./dashboard/export', id ]);
  }

  async onDelete(id) {
    //await this.router.navigate(['./dashboard/scalesview', id ]);
  }

}
