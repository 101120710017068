import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ScalesService } from "../../api/scales.service";
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from 'src/app/services/title.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-times-allRaw',
  templateUrl: './times-all.component.html',
  styleUrls: ['./times-all.component.css'],
  providers:[]
})
export class TimesAllComponent implements OnInit {

  constructor(private scale : ScalesService, private route: ActivatedRoute, private router: Router, private titleService: TitleService, private spinner: NgxSpinnerService) {
    this.titleService.setTitle($localize`Übersicht Arbeitszeiten`);
  }

  ngOnInit(): void {
    
  }
}
