import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private apiKey = "";
  private username = "";

  //private basePath = "http://192.168.178.40:8080/";
  //private basePath = "https://hermann.julian-w.de/";
  private basePath = "https://demo.sugi-app.com/";

  constructor(private http : HttpClient) { }

  loadData()
  {
    if(localStorage.getItem('apiKey') != null && localStorage.getItem('apiKey') != "")
    {
      this.apiKey = localStorage.getItem('apiKey');
      this.username = localStorage.getItem('username');
    }
  }



  async login(username, password)
  {
    var params = new HttpParams()
    .set('username', username)
    .set('password', password);

    var http = await this.http.get(this.basePath + "api/v1/auth/login", {params}).toPromise();    
    if(http['success'] == true)
    {
      console.log("login successful");
      this.apiKey = http['apikey'];
      this.username = username;
      localStorage.setItem("apiKey", this.apiKey);
      localStorage.setItem("username", this.username);
      return true;
    }
    else
    {
      console.log("login unsuccessful");
      return false;
    }
  }

  async logout()
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-API-KEY': this.apiKey,
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    await this.http.get(this.basePath + "api/v1/auth/logout", requestOptions).toPromise();

    this.apiKey = "";
    this.username = "";
    localStorage.setItem("apiKey", "");
    localStorage.setItem("username", "");
  }

  getApiKey() : string
  {
    return this.apiKey;
  }

  getUsername() : string
  {
    return this.username;
  }
  
  getBasePath() : string
  {
    return this.basePath;
  }

  isConnected () : boolean
  {
    if(this.apiKey.length > 5)  
      return true;
    else
      return false;
  }


}
