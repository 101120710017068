import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { HttpParams } from "@angular/common/http";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class ScalesService {

  constructor(private http : HttpClient, private auth : AuthService) { }

  async getAllScales()
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-API-KEY': this.auth.getApiKey()
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return await this.http.get(this.auth.getBasePath() + "api/v1/web/scales/all", requestOptions).toPromise();
  }

  async getScaleInfo(scaleID)
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-API-KEY': this.auth.getApiKey()
    }

    var params = new HttpParams()
      .set('id', scaleID);

    const requestOptions = {      
      params: params,                                                                                                                                                                           
      headers: new HttpHeaders(headerDict), 
    };

    return await this.http.get(this.auth.getBasePath() + "api/v1/web/scales/info", requestOptions).toPromise();
  }
  
  async saveScale(scaleID, name, comment, pin)
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-API-KEY': this.auth.getApiKey()
    }

    var params = new HttpParams()
      .set('id', scaleID)
      .set('name', name)
      .set('comment', comment)
      .set('pin', pin);

    const requestOptions = {      
      params: params,                                                                                                                                                                           
      headers: new HttpHeaders(headerDict), 
    };

    return await this.http.get(this.auth.getBasePath() + "api/v1/web/scales/save", requestOptions).toPromise();
  }
}
