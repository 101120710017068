<mat-card>  
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <img src="/assets/logo2.jpg" alt="sugi" width="150px">
        <br><br>
        <!--<h2>Waagen NFC Webportal</h2>
        <h3>Anmeldemaske</h3>-->
        <mat-error *ngIf="loginInvalid" i18n>
          Benutzername und Passwort passen nicht zusammen!
        </mat-error>
        <mat-error *ngIf="serverError" i18n>
          Login konnte nicht überprüft werden! Möglicherweise liegt ein Problem am Server vor.
        </mat-error>
        <mat-form-field class="full-width-input">
          <input matInput autocomplete="off" i18n-placeholder placeholder="Benutzername" formControlName="username" required>
          <mat-error i18n>
            Bitte gib deinen Benutzername ein!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input matInput type="password" autocomplete="off" i18n-placeholder placeholder="Passwort" formControlName="password" required>
          <mat-error i18n>
            Bitte gib dein Passwort ein!
          </mat-error>
        </mat-form-field>        
        <button mat-raised-button color="primary" i18n>Login</button>
      </form>
      <button mat-raised-button (click)="pwReset()" color="warn" style="margin:5px" i18n>Login zurücksetzen</button>
    </mat-card-content>
  </mat-card>  