import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ScalesService } from "../../api/scales.service";
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from 'src/app/services/title.service';
import { NgxSpinnerService } from 'ngx-spinner';

export interface ScaleData {
  id: number;
  name: string;
  comment: string;
}
@Component({
  selector: 'app-scales',
  templateUrl: './scales.component.html',
  styleUrls: ['./scales.component.css'],
  providers:[]
})
export class ScalesComponent implements OnInit {

  displayedColumns: string[] = ['id', 'name', 'comment', 'options'];
  dataSource: MatTableDataSource<ScaleData>;
  rawData : ScaleData[]

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private scale : ScalesService, private route: ActivatedRoute, private router: Router, private titleService: TitleService, private spinner: NgxSpinnerService) {
    this.titleService.setTitle($localize`Übersicht Waagen`);

    if(this.rawData == null)
    {
      this.spinner.show();
      scale.getAllScales().then((data) => this.onData(data));
    }
    else
    {
      this.dataSource = new MatTableDataSource(this.rawData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
   }

  onData(data) : void {
    this.spinner.hide();
    this.rawData = <ScaleData[]>data;
    this.dataSource = new MatTableDataSource(this.rawData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    
  }

  async onView(id) {
    await this.router.navigate(['./dashboard/scalesview', id ]);
  }

  async onEdit(id) {
    await this.router.navigate(['./dashboard/scalesedit', id ]);
  }

  async onDelete(id) {
    await this.router.navigate(['./dashboard/scalesview', id ]);
  }
}
