import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from "@angular/common/http";
import { HttpParams } from "@angular/common/http";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class HarvestEventsService {

  constructor(private http : HttpClient, private auth : AuthService) { }

  async getAllHarvestEvents()
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-API-KEY': this.auth.getApiKey()
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return await this.http.get(this.auth.getBasePath() + "api/v1/web/harvestevents/all", requestOptions).toPromise();
  }


  async getFilteredHarvestEvents(picker, products, scales, start, end)
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-API-KEY': this.auth.getApiKey()
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    let body = JSON.stringify(new Map([
        ["picker", picker],
        ["scales", products],
        ["products", scales],
        ["start", start],
        ["end", end]
    ]));

    return await this.http.post(this.auth.getBasePath() + "api/v1/web/harvestevents/filter", { picker: picker, products : products, scales : scales, start : start, end : end }, requestOptions).toPromise();
  }

  getFilteredHarvestEventsXLSX(picker, products, scales, start, end)
  {
    const headerDict = {
      'Content-Type': 'text/xlsx',
      'Accept': 'text/xlsx',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-API-KEY': this.auth.getApiKey()      
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict),      
      responseType: 'arraybuffer' as 'arraybuffer'
    };

    let body = JSON.stringify(new Map([
        ["picker", picker],
        ["scales", products],
        ["products", scales],
        ["start", start],
        ["end", end]
    ]));

    return this.http.post(this.auth.getBasePath() + "api/v1/web/harvestevents/filterDownloadXLSX", { picker: picker, products : products, scales : scales, start : start, end : end }, requestOptions);
  }

  getFilteredHarvestEventsCSV(picker, products, scales, start, end)
  {
    const headerDict = {
      'Content-Type': 'text/csv',
      'Accept': 'text/csv',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-API-KEY': this.auth.getApiKey()      
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict),      
      responseType: 'arraybuffer' as 'arraybuffer'
    };

    let body = JSON.stringify(new Map([
        ["picker", picker],
        ["scales", products],
        ["products", scales],
        ["start", start],
        ["end", end]
    ]));

    return this.http.post(this.auth.getBasePath() + "api/v1/web/harvestevents/filterDownload", { picker: picker, products : products, scales : scales, start : start, end : end }, requestOptions);
  }


  async getStats()
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-API-KEY': this.auth.getApiKey()
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return await this.http.get(this.auth.getBasePath() + "api/v1/web/harvestevents/getStats", requestOptions).toPromise();
  }
}
